import {createContext, useEffect, useReducer} from "react";

export const HeaderContext = createContext();


export const headerContextReducer = (state, action) => {
    switch (action.type){
        case "SET_HEADER":
            return{
                header: action.payload,
                current: state.current,
                previous: state
            }
        case "SET_CURRENT":
            return{
                header: state.header,
                current: action.payload,
                previous: state.previous
            }
        default:
            return state
    }
}

export const HeaderContextProvider = ({children}) => {
    const storedHeaderState = JSON.parse(localStorage.getItem("JBPortfolioHeaderStatus"))
    let storedHeader = false
    if (storedHeaderState){
        storedHeader = storedHeaderState.header
    }
    const [state, dispatch] = useReducer(headerContextReducer, {
        header: storedHeader,
        current: null,
        previous: null
    });

    useEffect(()=>{
        localStorage.setItem("JBPortfolioHeaderStatus", JSON.stringify(state))
    }, [state])

    return (
        <HeaderContext.Provider value={{...state, dispatch}}>
            {children}
        </HeaderContext.Provider>
    )
}