import menu from "../assets/menu.svg"
import { Link } from "react-router-dom";
import { useHeaderContext } from "../hooks/useHeaderContext";
import {AnimatePresence, motion} from "framer-motion"
import { useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";



const menuVariants = {
    hidden: {
        x:"100vh"
    }, visible: {
        x:0,
        transition:{ease:"linear", staggerChildren: 0.5}
    }, exit: {
        x:"100vh",
        transition:{ease:"linear"}
    }
}

const menuOptionVariants = {
    hidden: {
        opacity: 0
    }, visible: {opacity:1,transition:{ease:"linear"}},
    exit: {opacity:0,transition:{ease:"linear"}}
}

const scrollToTop = () => {
    window.scrollTo({top:0, left:0, behavior:"smooth"})
}

const scrollToAbout = (height) => {
    window.scrollTo({top:height, left:0, behavior:"smooth"})
}

export const menuLinkClickHandler = (toPage, innerHeight, dispatch) => {
    if (toPage==="Home" || toPage==="About Me"){
        dispatch({"type": "SET_HEADER", payload: false})
    } else {
        dispatch({"type": "SET_HEADER", payload: true})
    }
    if (toPage==="About Me"){
        scrollToAbout(innerHeight)
    } else{
        console.log("START2")
        scrollToTop()
        console.log("FIN2")
    }

}

const getHeaderClass = (current, showExt) => {
    if (showExt){
        if (current==="Experience"){
            return "main-header-short-G"
        }else{
            return "main-header-short"
        }
    }else{
        if (current==="Experience"){
            return "main-header-G"
        }else{
            return "main-header"
        }
    }
}

const pages = [
    ["About Me","/"],
    ["Experience","/Experience"],
    ["Technical Skills","/Technical"],
    ["Home","/"]
]

const Header = () => {
    const {header, current, previous, dispatch} = useHeaderContext();
    const [showExt, setShowExt] = useState(false)
    const {innerWidth, innerHeight} = useWindowSize();
    

    return (
        <AnimatePresence mode="wait">
        <motion.div className={current==="Experience"?"header-menu-holder-G":'header-menu-holder'}
        initial= {previous&&!previous.header&&{y:-100}}
        animate={{y:0, transition:{duration:0.3}}}
        exit={{y:-100, transition: {
            duration: 0.5
        }}}
        key={header}
        >
            <AnimatePresence>
            {showExt &&
            <motion.div className={innerWidth>1190?'menu-ext':"menu-ext-full"}
            variants={menuVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key={showExt}
            >
                <motion.div className="menu-ext-close"
                onClick={()=>{showExt ? setShowExt(false):setShowExt(true)}}
                variants={menuOptionVariants}
                key={showExt}
                >X</motion.div>
                <motion.div className="menu-ext-current" variants={menuOptionVariants} key={current}>
                {current}
                </motion.div>
                <motion.div className="menu-ext-options" variants={menuOptionVariants}>
                    {pages.map((page)=>{
                        if (page[0]!==current){
                            return (
                                
                                <motion.div className="menu-ext-option" variants={menuOptionVariants} key={page[0]}>
                                    <Link to={page[1]} onClick={()=>menuLinkClickHandler(page[0],innerHeight,dispatch)}>
                                        {page[0]}
                                    </Link>
                                </motion.div>

                            )
                        }
                    })}
                </motion.div>
            </motion.div>
            }
            </AnimatePresence>
            {header &&
            <motion.header className={getHeaderClass(current, showExt)} id="main-header"
            >
                <Link to="/" onClick={()=>menuLinkClickHandler("Home",innerHeight,dispatch)}>
                    <div>Josh Button</div>
                </Link>
                {!showExt &&
                <motion.img src={menu} className="menuButton" alt="Menu"
                onClick={()=>{showExt? setShowExt(false):setShowExt(true)}}
                initial={{opacity:0}}
                animate={{opacity:1,transition:{ease:"linear", delay:0.25}}}
                exit={{opacity:0,transition:{ease:"linear"}}}
                key={showExt}
                />
                }
            </motion.header>
            }
        </motion.div>
        </AnimatePresence>
    )
}

export default Header;