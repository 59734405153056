import { useEffect, useReducer} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {v4 as uuidv4} from "uuid";

import { useWindowSize } from "../hooks/useWindowSize";
import arrow from "../assets/Arrow-Right.svg";
import learn from "../assets/Technical/Learn.svg";
import stack from "../assets/Technical/Stack.svg";
import develop from "../assets/Technical/Develop.svg";
import Skill from "./Skill"

const skills = [
    "C",
    "Python",
    "Java",
    "C#" ,
    "JavaScript" ,
    "SQL" ,
    "Matlab/Octave",
    "React" ,
    "HTML and CSS" ,
    "Node.js" ,
    "MongoDB" ,
    "WPF" ,
    "Android Studio" ,
    "Express.js" ,
    "Agile Development"
]

const intervalTime = 5000

const arrowVariants = {
    standard:{
        opacity: 1,
        transition: {delay:1}
    },
    click:{
        opacity:0.6,
    }
}

const bannerTextVariants = {
    hidden:{
        opacity: 0
    }, visible:{
        opacity:1,
        transition:{
            duration: 0.5
        }
    }, visibleCursor:{
        opacity: [0,1],
        transition:{
            duration: 0.1,
            repeatType: "reverse",
            repeat: Infinity,
            repeatDelay: 0.4
        }
    },exit: {
        opacity: 0,
        transition:{
            duration: 0.5
        }
    }
}


const skillsReducer = (state, action) =>{
    switch (action.type){
        case "ARROW_FORWARD":
            let newSkillPointers = []
            let newSkillKeys = []
            let mainSkill = 0
            for (let i = 0; i<state.skillsPointers.length;i++){
                let number = state.skillsPointers[i]
                let newNumber = number+Math.floor(action.payload.displaying)
                if (newNumber >= action.payload.len){
                    newNumber = newNumber - (action.payload.len)
                }
                if (i === 0){
                    mainSkill = newNumber;
                }
                newSkillPointers.push(newNumber)
                newSkillKeys.push(uuidv4())
            }
            return {skillsPointers: newSkillPointers, skillKeys: newSkillKeys, mainPointer: mainSkill, mainKey: uuidv4()}
        case "SET_MAIN":
            return {skillsPointers: state.skillsPointers, skillKeys: state.skillKeys, mainPointer: action.payload, mainKey: uuidv4()}
        case "INCREMENT_MAIN":
            let newMainPointer = state.mainPointer+1
            if (newMainPointer>action.payload.len-2){
                newMainPointer = 0;
            }
            let mainPointer = state.mainPointer+1
            for (let i = 0; i< Math.floor(action.payload.displaying); i++){
                
                if (mainPointer === 0){
                    mainPointer = action.payload.len -1;
                } else{
                    mainPointer = mainPointer - 1
                }
            }
            if (mainPointer === state.skillsPointers[0]){
                let newSkillPointers = []
                let newSkillKeys = []
                let mainSkill = 0
                for (let i = 0; i<state.skillsPointers.length+1;i++){
                    let number = state.skillsPointers[i]
                    let newNumber = number+Math.floor(action.payload.displaying)
                    if (newNumber >= action.payload.len){
                        newNumber = newNumber - (action.payload.len)
                    }
                    if (i === 0){
                        mainSkill = newNumber;
                    }
                    newSkillPointers.push(newNumber)
                    newSkillKeys.push(uuidv4())
                }
                return {skillsPointers: newSkillPointers, skillKeys: newSkillKeys, mainPointer: mainSkill, mainKey: uuidv4()}
            }
            return {skillsPointers: state.skillsPointers, skillKeys: state.skillKeys, mainPointer: newMainPointer, mainKey: uuidv4()}
        default:
            return state
    }
}

const TechnicalOverview = () => {
    const {innerWidth, innerHeight} = useWindowSize();
    const skillsPointersStart = []
    const skillKeys = []
    for (let i = 0; i< skills.length;i++){
        skillsPointersStart.push(i);
        skillKeys.push(uuidv4());
    }

     const [skillsState, dispatchSkills] = useReducer(skillsReducer, {
        skillsPointers: [...skillsPointersStart],
        skillKeys: [...skillKeys],
        mainPointer: 0,
        mainKey: uuidv4()
    });

    useEffect( () => {
        let interval = null;
        if (innerWidth>1100){
            interval =  setInterval(() => {
                if (innerWidth>1800){
                    dispatchSkills({"type":"INCREMENT_MAIN", payload:{displaying: 12, len:skills.length}});
                }else{
                    dispatchSkills({"type":"INCREMENT_MAIN", payload:{displaying: (((innerWidth/100)*80)/((innerHeight/100)*11))-1, len:skills.length}});
                }
            }, intervalTime)
        } else {
            interval =  setInterval(() => {
                dispatchSkills({"type":"INCREMENT_MAIN", payload:{displaying: (Math.floor(((innerWidth/100)*48)/((innerHeight/100)*14)))*2, len:skills.length}});
            }, intervalTime)
        }
        return () => clearInterval(interval);
    }, [skillsState, innerHeight, innerWidth])

    return (
        <>
            {innerWidth > 1100
                ? <div className="technical-overview-wide">
                    <div className="big-skill-text">
                        <div className="big-skill-wide">
                            <div className="big-skill-holder-wide">
                            <AnimatePresence mode="wait">
                                <Skill key={skillsState.mainKey} skill={skills[skillsState.mainPointer]} main/>
                            </AnimatePresence>
                            </div>
                            
                            <div className="big-skill-label-wide">
                            <AnimatePresence mode="wait">
                            <motion.span
                            variants={bannerTextVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            key={skills[skillsState.mainPointer]}>
                                <div className="banner-text">I use </div>
                                
                                <motion.div
                                    
                                >{skills[skillsState.mainPointer]}</motion.div>
                                
                            </motion.span>
                            </AnimatePresence>
                            </div>
                            
                            
                        </div>
                        <div className="overview-text-wide">
                            
                        <h1>Technologies Change.</h1>
                            
                            <div className="learn-stack-develop-holder-wide">
                                <div className="learn-stack-develop">
                                    <img src={learn} className="learn-stack-develop-img" alt="Learn"/>
                                    <p className="learn-stack-develop-tag">Learn</p>
                                </div>
                                <div className="learn-stack-develop">
                                    <img src={stack} className="learn-stack-develop-img" alt="Stack"/>
                                    <p className="learn-stack-develop-tag">Stack</p>
                                </div>
                                <div className="learn-stack-develop">
                                    <img src={develop} className="learn-stack-develop-img" alt="Develop"/>
                                    <p className="learn-stack-develop-tag">Develop</p>
                                </div>
                            </div>

                            
                            <p>
                            Technology is always updating and improving. I believe the value of today’s engineer comes from their capacity to learn. <br/><br/> I hope that in any project I can pick the best technology for the job, not the most familiar.
                            Through this page, I illustrate my process of learning skills, combining them into stacks, and using those to develop applications.

                            </p>
                            
                            
                        </div>
                    </div>
                    <div className="small-skill-bar">
                    <AnimatePresence mode="popLayout">    
                        <div className="small-skill" key={skillsState.skillKeys[0]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[0]})}><Skill skill={skills[skillsState.skillsPointers[0]]} selected={skillsState.mainPointer===skillsState.skillsPointers[0]}/></div>
                        <div className="small-skill" key={skillsState.skillKeys[1]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[1]})}><Skill skill={skills[skillsState.skillsPointers[1]]} selected={skillsState.mainPointer===skillsState.skillsPointers[1]}/></div>
                        <div className="small-skill" key={skillsState.skillKeys[2]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[2]})}><Skill skill={skills[skillsState.skillsPointers[2]]} selected={skillsState.mainPointer===skillsState.skillsPointers[2]}/></div>
                        <div className="small-skill" key={skillsState.skillKeys[3]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[3]})}><Skill skill={skills[skillsState.skillsPointers[3]]} selected={skillsState.mainPointer===skillsState.skillsPointers[3]}/></div>
                        <div className="small-skill" key={skillsState.skillKeys[4]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[4]})}><Skill skill={skills[skillsState.skillsPointers[4]]} selected={skillsState.mainPointer===skillsState.skillsPointers[4]}/></div>
                        <div className="small-skill" key={skillsState.skillKeys[5]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[5]})}><Skill skill={skills[skillsState.skillsPointers[5]]} selected={skillsState.mainPointer===skillsState.skillsPointers[5]}/></div>
                        <div className="small-skill" key={skillsState.skillKeys[6]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[6]})}><Skill skill={skills[skillsState.skillsPointers[6]]} selected={skillsState.mainPointer===skillsState.skillsPointers[6]}/></div>
                        {(innerHeight/100)*11*9 < (innerWidth/100)*80 &&
                            <div className="small-skill" key={skillsState.skillKeys[7]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[7]})}><Skill skill={skills[skillsState.skillsPointers[7]]} selected={skillsState.mainPointer===skillsState.skillsPointers[7]}/></div>
                        }
                        {(innerHeight/100)*11*10 < (innerWidth/100)*80 &&
                            <div className="small-skill" key={skillsState.skillKeys[8]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[8]})}><Skill skill={skills[skillsState.skillsPointers[8]]} selected={skillsState.mainPointer===skillsState.skillsPointers[8]}/></div>
                        }
                        {(innerHeight/100)*11*11 < (innerWidth/100)*80 &&
                            <div className="small-skill" key={skillsState.skillKeys[9]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[9]})}><Skill skill={skills[skillsState.skillsPointers[9]]} selected={skillsState.mainPointer===skillsState.skillsPointers[9]}/></div>
                        }
                        {(innerHeight/100)*11*12 < (innerWidth/100)*80 &&
                            <div className="small-skill" key={skillsState.skillKeys[10]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[10]})}><Skill skill={skills[skillsState.skillsPointers[10]]} selected={skillsState.mainPointer===skillsState.skillsPointers[10]}/></div>
                        }
                        {(innerHeight/100)*11*13 < (innerWidth/100)*80 &&
                            <div className="small-skill" key={skillsState.skillKeys[11]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[11]})}><Skill skill={skills[skillsState.skillsPointers[11]]} selected={skillsState.mainPointer===skillsState.skillsPointers[11]}/></div>
                        }
                    </AnimatePresence>
                        <motion.img src={arrow} className="small-skill-arrow" alt="Arrow-right" time={Date.now()} 
                        variants={arrowVariants}
                        animate={"standard"}
                        whileTap={"click"}
                        onClick={(e)=>{
                            if (Date.now() - e.target.getAttribute("time") > 1100){

                                dispatchSkills({"type":"ARROW_FORWARD", payload:{displaying: (((innerWidth/100)*80)/((innerHeight/100)*11))-1, len:skills.length}});
                                e.target.time = Date.now()
                            }
                        }}/>
                    
                    </div>
                </div>


                : 


                <div className="technical-overview">
                    <div 
                    className={innerWidth > 530 ? "banner-statement"
                        : "banner-statement-short"}>
                        <div className="banner-text">Technologies {innerWidth < 530 && <br/>}Change.</div>
                    </div>
                    <div className="skill-icons">
                        <div className="big-skill-holder">
                            <div className="big-skill">
                                <AnimatePresence mode="wait">
                                    <Skill key={skillsState.mainKey} skill={skills[skillsState.mainPointer]} main/>
                                </AnimatePresence>
                            </div>
                            <div className={innerWidth>780?"big-skill-label":"big-skill-label-short"}>
                                <AnimatePresence mode="wait">
                                <motion.span
                                variants={bannerTextVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                key={skills[skillsState.mainPointer]}
                                >
                                <div className="banner-text-short">I use</div>
                                <div>{skills[skillsState.mainPointer]}</div>
                                </motion.span>
                                </AnimatePresence>
                            </div>
                        </div>
                        <div className="little-skills">
                            <div className="little-skills-upper">
                            <AnimatePresence mode="popLayout"> 
                                <div className="little-skill" key={skillsState.skillKeys[0]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[0]})}>
                                    <Skill skill={skills[skillsState.skillsPointers[0]]} selected={skillsState.mainPointer===skillsState.skillsPointers[0]}/>
                                </div>
                                {(innerHeight/100)*14*2 < (innerWidth/100)*48 &&
                                    <div className="little-skill" key={skillsState.skillKeys[2]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[2]})}>
                                        <Skill skill={skills[skillsState.skillsPointers[2]]} selected={skillsState.mainPointer===skillsState.skillsPointers[2]}/>
                                    </div>
                                } 
                                {(innerHeight/100)*14*3 < (innerWidth/100)*48 &&
                                    <div className="little-skill" key={skillsState.skillKeys[4]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[4]})}>
                                        <Skill skill={skills[skillsState.skillsPointers[4]]} selected={skillsState.mainPointer===skillsState.skillsPointers[4]}/>
                                    </div>
                                }
                            </AnimatePresence> 
                            </div>
                            <div className="little-skills-lower">
                                <AnimatePresence mode="popLayout">
                                <div className="little-skill" key={skillsState.skillKeys[1]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[1]})}>
                                    <Skill skill={skills[skillsState.skillsPointers[1]]} selected={skillsState.mainPointer===skillsState.skillsPointers[1]}/>
                                </div>
                                {(innerHeight/100)*14*2 < (innerWidth/100)*48 &&
                                    <div className="little-skill" key={skillsState.skillKeys[3]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[3]})}>
                                        <Skill skill={skills[skillsState.skillsPointers[3]]} selected={skillsState.mainPointer===skillsState.skillsPointers[3]}/>
                                    </div>
                                } 
                                {(innerHeight/100)*14*3 < (innerWidth/100)*48 &&
                                    <div className="little-skill" key={skillsState.skillKeys[5]} onClick={()=>dispatchSkills({"type":"SET_MAIN", payload:skillsState.skillsPointers[5]})}>
                                        <Skill skill={skills[skillsState.skillsPointers[5]]} selected={skillsState.mainPointer===skillsState.skillsPointers[5]}/>
                                    </div>
                                }
                                </AnimatePresence> 
                            </div>
                        </div>
                        <motion.img src={arrow} className="skill-arrow" alt="Arrow-right" time={Date.now()} 
                        variants={arrowVariants}
                        animate={"standard"}
                        whileTap={"click"}
                        onClick={(e)=>{
                            if (Date.now() - e.target.getAttribute("time") > 1100){
                                dispatchSkills({"type":"ARROW_FORWARD", payload:{displaying: (((innerWidth/100)*48)/((innerHeight/100)*14))*2, len:skills.length}});
                                e.target.time = Date.now()
                            }
                        }}/>
                    </div>
                    <div className="overview-text">
                            <p>
                            Technology is always updating and improving. I believe the value of today’s engineer comes from their capacity to learn. <br/><br/> I hope that in any project I can pick the best technology for the job, not the most familiar.
                            Through this page, I illustrate my process of learning skills, combining them into stacks, and using those to develop applications.

                            </p>
                            <div className="learn-stack-develop-holder">
                                <div className="learn-stack-develop">
                                    <img src={learn} className="learn-stack-develop-img" alt="Learn"/>
                                    <p className="learn-stack-develop-tag">Learn</p>
                                </div>
                                <div className="learn-stack-develop">
                                <img src={stack} className="learn-stack-develop-img" alt="Stack"/>
                                <p className="learn-stack-develop-tag">Stack</p>
                                </div>
                                <div className="learn-stack-develop">
                                <img src={develop} className="learn-stack-develop-img" alt="Develop"/>
                                <p className="learn-stack-develop-tag">Develop</p>
                                </div>
                            </div>
                    </div>
                </div>
            }
        </> 
    ) 
}


export default TechnicalOverview;