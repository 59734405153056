import TechnicalOverview from "../components/TechnicalOverview"
import TechnicalScrollArrow from "../components/TechnicalScrollArrow";

import timeline from "../assets/Technical/CTimeline.png";
import timelineV from "../assets/Technical/CTimelineV.png";
import mern1 from "../assets/Technical/MERN1.png";
import mern2 from "../assets/Technical/MERN2.png";
import mern3 from "../assets/Technical/MERN3.png";
import dd1_1 from "../assets/Technical/DD1_1.png";
import dd1_2 from "../assets/Technical/DD1_2.png";
import dd1_3 from "../assets/Technical/DD1_3.png";
import dd1_4 from "../assets/Technical/DD1_4.png";
import dd2_1 from "../assets/Technical/DD2_1.png";
import dd2_2 from "../assets/Technical/DD2_2.png";
import dd2_3 from "../assets/Technical/DD2_3.png";
import dd2_4 from "../assets/Technical/DD2_4.png";

import { useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import { useHeaderContext } from "../hooks/useHeaderContext";
import TechnicalTechUsed from "../components/TechnicalTechUsed";
import ImageShowcase from "../components/ImageShowcase";
import { useWindowSize } from "../hooks/useWindowSize";
import SelectorContentOption from "../components/SelectorContentOption.js";


const pageVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition:{
            duration: 0.5,
            staggerChildren: 0.3
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5
        }
    }
}

const changeIndex = (changeFunction, index, current) => {
    if (current===index){
        changeFunction(null);
    } else {
        changeFunction(index);
    }
    console.log(current)
}

const indexRenderer = (index) => {
    switch (index){
        case 0:
            return (
                <motion.div className="technical-page-selector-content-column" 
                variants={pageVariants}
                initial={"hidden"}
                animate={"visible"}
                exit={"exit"}
                key={"tpscc0"}>
                    <span className="Java">
                    I like to take as many opportunities as possible to be entrepreneurial. As someone with a background in tech, one way I can provide value to a team is by building and maintaining a platform - such as a mobile app or website. <br/> <br/>
                    This has driven me to learn how to make native apps on Android, using my existing Java knowledge. I am far into the learning process, and looking forward to starting my first major project very soon. <br/> <br/>

                    </span>
                    <TechnicalTechUsed elements={["Java", "Android Studio"]}/>
                </motion.div>
            )
        case 1:
            return (
                <motion.div className="technical-page-selector-content-column" 
                variants={pageVariants}
                initial={"hidden"}
                animate={"visible"}
                exit={"exit"}
                key={"tpscc1"}>
                    <span className="SQL">
                        In almost every major application there will be a database of some kind. It is an essential skill for any software developer to be able to create, modify and maintain these systems.<br/> <br/>
                        Relational databases, often accessed through SQL, are the most commonly used type of databases. <br/> <br/>
                        Throughout all of my personal projects, I have built my knowledge of SQL and databases, being used in almost every single one. I have used a strong variety of technologies and platforms such as SQLite, MySQL, MongoDB. I have also used ORMs such as Mongoose.<br/> <br/>
                        I have also spent time developing my theoretical knowledge, having studied relational database theory throughout my degree.
                    </span>
                    <TechnicalTechUsed elements={["SQL", "MongoDB"]}/>
                </motion.div>
            )
        case 2: //2 or other
            return (
                <motion.div className="technical-page-selector-content-column" 
                variants={pageVariants}
                initial={"hidden"}
                animate={"visible"}
                exit={"exit"}
                key={"tpscc2"}>
                    <span className="Python">
                        A lot of people are overly familiar with Python. <br/><br/>
                        Like many who study computer science, the first language I was taught was Python. In education, the ability to start quickly makes the language ideal for teaching. However, this almost becomes a problem. A lot of people fall into the trap of not exploring other languages. Its very easy to do. Python is very versatile. <br/><br/>
                        Having moved away from Python initially, I came to appreciate the decision to do so. The exposure to other languages and technology taught me a lot. Despite this, when covering more data heavy subjects at university, such as AI, I came to appreciate the language a lot more. I found myself frequently using Python to reinforce points being taught, as well as exploring the areas further. Having revisited with a better understanding of the surrounding theory, I found myself better equipped to make the most of the libraries and associated frameworks of Python. 
                    </span>
                    <TechnicalTechUsed elements={["Python", "SQL"]}/>
                </motion.div>
            )
        default:
            return(
                <div className="technical-page-selector-content-empty"></div>
            )
    }
}


const Technical = () => { 
    const {dispatch} = useHeaderContext();
    const pageName = "Technical Skills";
    const {innerWidth, innerHeight} = useWindowSize();
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        dispatch({"type":"SET_HEADER", payload:true});
        dispatch({"type": "SET_CURRENT", payload: pageName});
    }, [dispatch])


    return (
        <motion.div
        variants={pageVariants} 
        exit="exit"
        animate="visible"
        initial="hidden">
            <div className="technical" id="technical-main">
                <TechnicalOverview/>
            </div>
            <TechnicalScrollArrow arrowNo={"0"}/>
            <div className="technical-page">
                <div className="technical-page-banner">
                    The MERN Stack      
                </div>
                <div className="technical-page-content">
                    <div className="technical-page-content-column"><span className="MERN">
                        <div>
                        In May 2024 I started to learn how to build a full stack MERN website.<br/> <br/>
                        MERN stands for MongoDB, Express.js, React and Node.js, and is a JS tech stack to cover both the front and back end of a website. <br/> <br/>
                        I chose to learn MERN because I wanted to be able to build a website, and there were lots of resources available online. A good place to start when introducing new concepts. <br/> <br/>
                        </div>
                        <div>
                        I started with the backend, learning how to use Node.js and Express. Then learning to pair this with requests to a MongoDB database.<br/> <br/>
                        After this, the frontend using React was studied. <br/> <br/>
                        With all the pieces complete, I started to build this portfolio website. I saw this as a great opportunity to reinforce existing learning with real world experience. It was also a chance to develop my design skills, learning to cater for a variety of different users with a range of device sizes and capabilities. <br/> <br/>
                        </div>
                    </span></div>
                    <div className="technical-page-content-column">
                        <div className="technical-page-image-showcase-holder">
                            <ImageShowcase images={[
                                [mern1, "Building the Code"],
                                [mern2, "Screen Size Variety"],
                                [mern3, "Designing Graphics"],
                            ]}/>
                        </div>
                        <TechnicalTechUsed elements={["MongoDB", "Express.js", "React", "Node.js", "HTML", "CSS", "JavaScript"]}/>
                    </div>
                </div>
            </div>
            <TechnicalScrollArrow arrowNo={"1"}/>
            
            <div className="technical-page">
                <div className={innerWidth< 672 ? "technical-page-banner-short":"technical-page-banner"}>
                    C, Assembly, and Embedded Software      
                </div>
                <div className="technical-page-content">
                    <div className="technical-page-content-column"><span className="C">
                        <div>
                        After my first year at university, I secured an internship at MBDA Missile Systems as a software engineer. This was my introduction to many important concepts, such as agile development, pair programming and version control. <br/><br/>
                        </div>
                        <div>
                        Embedded software, commonly coded using C and Assembly language, is very strict and very specific to the device it runs on. Think the software on a cash machine or self-checkout kiosk. <br/> <br/>
                        I needed to ensure that I could stand by my code. This meant taking ownership of my skills, and taking additional time to learn the language C, which I was not yet comfortable with. <br/> <br/>
                        I would love to be specific about my contributions and my time during my internship. However, MBDA is a defence contractor, and there are strict security restrictions. I take these very seriously. <br/> <br/>
                        </div>
                    </span></div>
                    <div className="technical-page-content-column">
                        <div className="C-img-holder">
                            <AnimatePresence mode="wait">
                            <motion.img src={innerHeight*1.2>innerWidth ? timelineV : timeline} key={innerHeight*1.2>innerWidth} className={innerHeight*1.2>innerWidth ?"C-img-v":"C-img"}
                                variants={pageVariants}
                                initial={"hidden"}
                                animate={"visible"}
                                exit={"exit"}
                                />
                            </AnimatePresence>
                            <span id="C">After my internship, I continued building my knowledge of C by learning about the responsibilities of the Operating System on modern machines, taking the course Operating Systems at my university.</span>
                        </div>
                        
                        <TechnicalTechUsed elements={["C", "Matlab / Octave", "Agile Development", "Assembly Code"]}/>
                    </div>
                </div>
            </div>
            <TechnicalScrollArrow arrowNo={"2"}/>   

            <div className="technical-page">
                <div className="technical-page-banner">
                    Desktop Development     
                </div>
                <div className="technical-page-content">
                    <div className="technical-page-content-column"><span className="Desktop">
                    <div>
                    During the lockdown of 2020, I set myself the challenge of learning a new programming language, fully independently. I bought an 800-page book and followed it through, making mini projects to help reinforce learning. I had learnt a lot of transferable technical skills, but had nothing to apply them to. So, I chose to undertake a project topical and relevant to at the time.<br/><br/>
                    </div><div>
                    I developed a Windows desktop application using C# and WPF to model the transmission of Covid-19 in schools. It analysed timetable data to present school administrators with a visual guide on which students where most at risk, based on the data of an observed case. It worked, but not well.  <br/><br/>
                    Using the lessons I had learnt; I built my skills further by producing another application. Like most students whose first instrument is the guitar, I struggled massively with improvising – a key part of the grade exam. I built an app to allow students to create short reusable musical phrases based on a visualisation of a guitar neck, helping students to develop their theoretical knowledge. <br/><br/>
                    </div>
                    </span></div>
                    <div className="technical-page-content-column">
                        <div className={(28*innerHeight > (23*innerWidth))?"technical-page-image-showcase-holder-short": "technical-page-image-showcase-holder"}>
                            <div className="technical-page-image-showcase-holder-sub" key="imgShowcaseDD1">
                                <ImageShowcase images={[
                                    [dd1_1, "P1: Dataset Selection"],
                                    [dd1_2, "P1: Case Input"],
                                    [dd1_3, "P1: Orbit Risk Graph"],
                                    [dd1_4, "P1: Code Building"]
                                ]}/>
                            </div>
                            <div className="technical-page-image-showcase-holder-sub"  key="imgShowcaseDD2">
                                <ImageShowcase images={[
                                    [dd2_1, "P2: Plotting Tab"],
                                    [dd2_2, "P2: Key Help"],
                                    [dd2_3, "P2: UI Design"],
                                    [dd2_4, "P2: Code Building"]
                                ]}/>
                            </div>
                        </div>
                        <TechnicalTechUsed elements={["C#", "WPF", "SQL (SQLite)"]}/>
                    </div>
                </div>
            </div>
            <TechnicalScrollArrow arrowNo={"3"}/>   

            <div className="technical-page">
                <div className="technical-page-selector-content">
                    <div className="technical-page-selector-content-empty">
                        <SelectorContentOption titleName="Java and Android Studio" selected={0 === selectedIndex} onClick={()=>{changeIndex(setSelectedIndex, 0, selectedIndex)}}/>
                        <SelectorContentOption titleName="SQL and Relational Database Theory" selected={1 === selectedIndex} onClick={()=>{changeIndex(setSelectedIndex, 1, selectedIndex)}}/>
                        <SelectorContentOption titleName="Python and Data Science" selected={2 === selectedIndex} onClick={()=>{changeIndex(setSelectedIndex, 2, selectedIndex)}}/>
                    </div>
                    <AnimatePresence mode="wait">
                        {indexRenderer(selectedIndex)}
                    </AnimatePresence>
                </div>
            </div>
            <TechnicalScrollArrow arrowNo={"4"}/>

       
        </motion.div>
    )
};

export default Technical;