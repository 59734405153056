import { HeaderContext } from "../context/HeaderContext";
import { useContext } from "react";

export const useHeaderContext = () => {
    const context = useContext(HeaderContext);

    if (!context){
        throw Error("useHeaderContext must be used inside a HeaderContext")
    }

    return context;
}