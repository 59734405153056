
import arrowRight from "../assets/Arrow-Right.svg"
import arrowLeft from "../assets/Arrow-Left.svg"


import {v4 as uuidv4} from "uuid";
import {AnimatePresence, motion} from "framer-motion" 
import { useReducer } from "react"

const arrowVariants = {
    standard:{
        opacity: 1,
        transition: {delay:1}
    },
    click:{
        opacity:0.4,
    }
}

const imageVariants = {
    hidden:{
        opacity: 0,
        transition: {delay:0.5}
    },
    visible:{
        opacity:1,
    },
    exit:{
        opacity: 0,
        transition: {duration:0.5}
    }
}

const imageReducer = (state, action) => {
    switch (action.type){
        case "FORWARD":
            let newPointerF
            if (state.pointer+1>state.images.length-1){
                newPointerF = 0;
            }
            else{
                newPointerF = state.pointer+1
            }
            return {images: state.images, pointer:newPointerF}
        case "BACKWARD":
            let newPointerB;
            if (state.pointer-1<0){
                newPointerB = state.images.length-1;
            } else {
                newPointerB = state.pointer-1
            }
            return {images: state.images, pointer:newPointerB}
        default:
            return state
    }
}

const ImageShowcase = ({images}) => {
    const [imageState, dispatch] = useReducer(imageReducer, {
        images: [...images],
        pointer: 0
    });
    return(
        <div className="image-showcase">
            <div className="image-showcase-image-holder">
                <AnimatePresence mode="wait">
                    <motion.img className="image-showcase-image" src={imageState.images[imageState.pointer][0]} alt=""
                    key={imageState.images[imageState.pointer][0]}
                    variants={imageVariants}
                    initial={"hidden"}
                    animate={"visible"}
                    exit={"exit"}
                    />
                </AnimatePresence>
            </div>
            <div className="image-showcase-scroller">
                <AnimatePresence mode="wait">
                    <motion.div className="image-showcase-scroller-description"
                        key={uuidv4()}
                        variants={imageVariants}
                        initial={"hidden"}
                        animate={"visible"}
                        exit={"exit"}
                    ><span>{imageState.images[imageState.pointer][1]}</span></motion.div>
                </AnimatePresence>
                <div className="image-showcase-scroller-arrows">
                    <motion.img className="image-showcase-scroller-arrow" src={arrowLeft}
                        alt="Arrow-Left" 
                        time={Date.now()} 
                        variants={arrowVariants}
                        animate={"standard"}
                        whileTap={"click"}
                        onClick={(e)=>{
                            if (Date.now() - e.target.getAttribute("time") > 1100){
                                dispatch({"type":"BACKWARD"});
                                e.target.time = Date.now()
                            }
                        }}/>
                    <motion.img className="image-showcase-scroller-arrow" src={arrowRight} 
                        alt="Arrow-Right" 
                        time={Date.now()} 
                        variants={arrowVariants}
                        animate={"standard"}
                        whileTap={"click"}
                        onClick={(e)=>{
                            if (Date.now() - e.target.getAttribute("time") > 1100){
                                dispatch({"type":"FORWARD"});
                                e.target.time = Date.now()
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ImageShowcase