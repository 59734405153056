import { useScrollY } from "../hooks/useScrollY";
import { useWindowSize } from "../hooks/useWindowSize";
import {AnimatePresence, motion} from "framer-motion";

import arrow from "../assets/Arrow-Down.svg"


const arrowVariants = {
    hidden:{
        opacity:0
    }, visible:{
        opacity: 1,
        transition: {
            delay:1.2
        }
    }
}

const handleClick = (arrowNo, up) => {
    try{
        if (up){
            document.getElementById("tD"+arrowNo.toString()).scrollIntoView({behavior: "smooth", block:"end", inline:"nearest"}) 
        }else{
            document.getElementById("tD"+(Number(arrowNo)+1).toString()).scrollIntoView({behavior: "smooth", block:"end", inline:"nearest"})
        }
    } catch {

    }
}

const TechnicalScrollArrow = ({arrowNo}) => {
    const scrollY = useScrollY();
    const {innerHeight} = useWindowSize();
    const arrowID = "tD"+arrowNo
    
   
    return (
            <AnimatePresence mode="wait">
                <motion.img className="technical-down" src={arrow} id={arrowID}
                    variants={arrowVariants}
                    initial="hidden"
                    animate="visible"
                    onClick={()=>handleClick(arrowNo, scrollY > innerHeight*(Number(arrowNo)+0.25))}
                    alt=""
                />
            </AnimatePresence>
    )

}

export default TechnicalScrollArrow