import { useEffect } from "react"
import { Navigate, useLocation } from "react-router-dom"

const Renav = () => {
    let location = useLocation();
    try{
    fetch(`${process.env.REACT_APP_API_URL}/api/log/renav`, {
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify({location})
    })
    } catch {}
    
    return (
        <Navigate to="" replace/>
    )
}

export default Renav