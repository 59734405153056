import { useEffect} from "react";
import { useHeaderContext } from "../hooks/useHeaderContext"
import { Link } from "react-router-dom";
import {motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "../hooks/useWindowSize";
import { menuLinkClickHandler } from "../components/Header";




const Home = () =>{
    const {dispatch} = useHeaderContext();
    const {ref, inView} = useInView()
    const {innerHeight} = useWindowSize();

    const pageVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition:{
                duration: 0.5,
                staggerChildren: 0.3
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.5
            }
        }
    }

    useEffect(() => {
        if (!inView){
            dispatch({"type": "SET_HEADER", payload: true})
            dispatch({"type": "SET_CURRENT", payload: "About Me"})
        } else {
            dispatch({"type": "SET_HEADER", payload: false})
            dispatch({"type": "SET_CURRENT", payload: "Home"})
        }
    }, [inView, dispatch])

    return (
        <>
        <motion.div className="home" 
        variants={pageVariants} 
        exit={"exit"}
        animate="visible"
        initial="hidden">
            <div className="home-text">
                <div className="home-profile">
                    <div className="home-profile-name">
                        <p>Josh <br/> Button</p>
                    </div>
                </div>
                <div className="home-tagline">
                </div>
            </div>
            <div className="home-buttons">
                <span>
                    <div className="home-button" id="hB1">
                        <Link to="/Experience" onClick={()=>menuLinkClickHandler("Experience", innerHeight, dispatch)}>
                        <p className="home-button-text">Experience</p>
                        </Link>
                    </div>
                    <div className="home-button" ref={ref} id="hB2">
                        <Link to="/Technical" onClick={()=>menuLinkClickHandler("Technical", innerHeight, dispatch)}>
                        <p className="home-button-text">Technical <br/> Skills</p>
                        </Link>
                    </div>
                </span>
                <span>
                    <div className="home-button" id="hB3">
                        <Link to="/" onClick={()=>{document.getElementsByClassName("about")[0].scrollIntoView({behavior: "smooth", block:"end", inline:"nearest"})}}>
                        <p className="home-button-text">About <br/> Me</p>
                        </Link>
                    </div>
                </span>
            </div>
        </motion.div>
        <div className="home-about-gap"></div>
        <motion.div className="about">
            <div className="about-title">
                About Me
            </div>
            <div className="about-content">
            I am a learner, and soon-to-be graduate of computer science. I love the creativity of working with technology, particularly software. <br/><br/>
            After working with traditionally backend software, I have learnt the popular framework React to create a full stack (MERN) portfolio website.  <br/><br/>
            The Technical Skills page details my attitude to technology, and my learning process. <br/><br/>
            The Experience page shows the steps I have taken to build my experience. <br/><br/>
            In my personal time, I greatly enjoy music, both listening and playing. I take any chance I can to play collaboratively. I believe this has shaped my person, haven taken the time to learn electric guitar, bass guitar and the drums.<br/><br/>
            Thank you for taking the time to visit my site.

            </div>
        </motion.div>
        </>
    )
}

export default Home;