import React, { useEffect } from 'react';
import {useLocation, useRoutes} from 'react-router-dom'
import {v4 as uuidv4} from "uuid";
import Home from "./pages/Home";
import Header from "./components/Header";
import Technical from "./pages/Technical";
import Experience from './pages/Experience';
import Renav from './components/Renav';
import { HeaderContextProvider } from './context/HeaderContext';
import {AnimatePresence} from 'framer-motion';



function appClass(pathname) {
  switch (pathname){
    case "/Experience":
      return "App-black";
    case "/":
      return "App-gradient"
    default:
      return "App"
  }
}

function App() { 
  
  const element = useRoutes([
    {
      path: "",
      element: <Home key={"Home"}/>
    },
    {
      path: "/Technical",
      element: <Technical/>
    },
    {
      path: "/Experience",
      element: <Experience/>
    },
    {
      path: "*",
      element: <Renav/>
    }
  ]);

  const location = useLocation();
  
  useEffect(()=> {
    console.log(`${process.env.REACT_APP_API_URL}/api/log/page`)
    try{
    console.log(fetch(`${process.env.REACT_APP_API_URL}/api/log/page`, {
      method:"POST",
      headers:{'Content-Type':'application/json'},
      body: JSON.stringify({location})
    }).json())
    } catch {}
  }, [location])

  if (!element) return null;

  return (
    <div className={appClass(location.pathname)}>
      <HeaderContextProvider>
            <Header key={uuidv4()}/>
            <div className='pages' id="pages">
              
              <AnimatePresence mode='wait'>
              {React.cloneElement(element, {key: location.pathname})}
              </AnimatePresence>
            </div>
      </HeaderContextProvider>
    </div>
  );
}

export default App;
