import { useEffect } from "react";
import { useHeaderContext } from "../hooks/useHeaderContext"
import { useWindowSize } from "../hooks/useWindowSize";
import {motion} from "framer-motion"
import uoa from "../assets/uoa.png"
import mbda from "../assets/mbda.png"

const pageVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition:{
            duration: 0.5,
            staggerChildren: 0.8
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5
        }
    }
}

const standardOpacity = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        delay: 0.6,
        transition:{
            duration: 1.4,
        }
    }
}

const svgVariants = {
    hidden:{
      opacity: 0
    }, visible: {
      opacity: 1,
      transition: {
        duration:1,
      }
    }
  }

const pathVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    }, visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        delay:0.6,
        duration: 0.8,
        ease: "easeInOut"
      }
    }
}

const pathVariantsHead = {
    hidden: {
      pathLength: 0,
      opacity:0
    }, visible: {
      pathLength: 1,
      opacity:1,
      transition: {
        pathLength: {
            duration: 0.6,
            ease: "easeInOut",
            delay:1.4
        },
        opacity: {
            duration:0,
            delay:1.4
        }
      }
    }
}

const timelineVariants = {
    offscreen: {
        x: 0
    }, onscreen: {
        x: "10vw",
        transition: {
            duration: 0.6
        }
    }
}

const timelineContentVariants = {
    offscreen: {
        scale: 1
    }, onscreen: {
        scale:1.05,
        transition: {
            duration: 0.6
        }
    }
}

const Experience = () =>{
    const pageName = "Experience"
    const {dispatch} = useHeaderContext();
    const {innerWidth} = useWindowSize();
    const changeWidth = 500;

    useEffect(() => {
        dispatch({"type": "SET_HEADER", payload: true})
        dispatch({"type": "SET_CURRENT", payload: pageName})
    }, [dispatch])

    return (
        <motion.div className="experience"
        variants={pageVariants} 
        exit="exit"
        animate="visible"
        initial="hidden"
        >
            <motion.div className="experience-welcome" variants={standardOpacity}>
                Scroll Through <br/> My Timeline
            </motion.div>
            <div className="experience-welcome-arrow-holder">
                <motion.svg
                viewBox="0 0 100 100"
                className="experience-welcome-arrow"
                variants={svgVariants}
                animate="visible"
                initial="hidden"
                >
                    <motion.path
                    fill="none"
                    d="M50 15 L50 80 Z"
                    variants={pathVariants}
                    />
                    <motion.path
                    fill="none"
                    d="M50 80 L65 70 Z"
                    variants={pathVariantsHead}
                    />
                    <motion.path
                    fill="none"
                    d="M50 80 L35 70 Z"
                    variants={pathVariantsHead}
                    />
                </motion.svg>
            </div>
            <div className="timeline">
                <div className="timeline-line-empty">
                    <div className="timeline-line-holder">
                        <motion.svg
                        viewBox="0 0 10 250"
                        className="timeline-line-svg-empty"
                        variants={svgVariants}
                        animate="visible"
                        initial="hidden">
                            <motion.path
                            fill="none"
                            d="M5 240 L5 10 Z"
                            variants={pathVariants}/>
                        </motion.svg>
                    </div>
                </div>
                <motion.div className="timeline-section"
                    variants={timelineVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{amount:0.8}}
                >
                    <div className="timeline-date">
                        September 2020
                    </div>
                    <div className="timeline-line">
                        <div className="timeline-line-holder">
                            <motion.svg
                            viewBox="0 0 10 800"
                            className="timeline-line-svg"
                            >
                                <motion.path
                                fill="none"
                                d="M5 790 L5 10 Z"
                                variants={pathVariants}/>
                            </motion.svg>
                        </div>
                        <motion.div className="timeline-content" variants={timelineContentVariants}>

                            <motion.div className={innerWidth<600?"timeline-content-2":"timeline-content-1"}>
                                Sixth Form
                            </motion.div>
                            <motion.div className="timeline-content-3">
                                Simon Balle School
                            </motion.div>
                            <motion.div className={innerWidth<changeWidth?"timeline-content-2-5":"timeline-content-2"}>
                                A*: Mathematics
                            </motion.div>
                            <motion.div className={innerWidth<changeWidth?"timeline-content-2-5":"timeline-content-2"}>
                                A : Further Mathematics
                            </motion.div>
                            <motion.div className={innerWidth<changeWidth?"timeline-content-2-5":"timeline-content-2"}>
                                A : Computer Science
                            </motion.div>
                            <motion.div className="timeline-content-3">
                                STEM GCSE Tutor
                            </motion.div>
    
                        </motion.div>
                    </div>
                    <div className="timeline-date">
                        August 2022
                    </div>
                </motion.div>
                <div className="timeline-line-empty">
                    <div className="timeline-line-holder">
                        <motion.svg
                        viewBox="0 0 10 250"
                        className="timeline-line-svg-empty">
                            <motion.path
                            fill="none"
                            d="M5 240 L5 10 Z"
                            />
                        </motion.svg>
                    </div>
                </div>
                <motion.div className="timeline-section"
                variants={timelineVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{amount:0.8}}>
                    <div className="timeline-date">
                        September 2022
                    </div>
                    <div className="timeline-line">
                        <div className="timeline-line-holder">
                            <motion.svg
                            viewBox="0 0 10 800"
                            className="timeline-line-svg">
                                <motion.path
                                fill="none"
                                d="M5 790 L5 10 Z"
                                />
                            </motion.svg>
                        </div>
                        <motion.div className="timeline-content" variants={timelineContentVariants}>
                            <div className="timeline-content-img-holder">
                                <img src={uoa} alt=""/>
                            </div>
                            <motion.div className="timeline-content-2">
                                University {innerWidth>=500&&"of"} Aberdeen
                            </motion.div>
                            <motion.div className="timeline-content-5">
                                <a href="https://www.theguardian.com/education/ng-interactive/2024/sep/07/the-guardian-university-guide-2025-the-rankings">(12th in UK)</a>
                            </motion.div>
                            <motion.div className="timeline-content-3">
                                Year 1:
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Algorithms and Data Structures
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Databases and Data Management
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Linear Algebra
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Human Computer Interaction
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Mathematics for Computer Science
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Computer Systems and Architecture
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Software Programming
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Marketing
                            </motion.div>
                        </motion.div>
                    </div>
                    <div className="timeline-date">
                        May 2023
                    </div>
                </motion.div>
                <div className="timeline-line-empty">
                    <div className="timeline-line-holder">
                        <motion.svg
                        viewBox="0 0 10 250"
                        className="timeline-line-svg-empty">
                            <motion.path
                            fill="none"
                            d="M5 240 L5 10 Z"
                            />
                        </motion.svg>
                    </div>
                </div>
                <motion.div className="timeline-section"
                variants={timelineVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{amount:0.8}}>
                    <div className="timeline-date">
                        June 2023
                    </div>
                    <div className="timeline-line">
                        <div className="timeline-line-holder">
                            <motion.svg
                            viewBox="0 0 10 800"
                            className="timeline-line-svg">
                                <motion.path
                                fill="none"
                                d="M5 790 L5 10 Z"
                                />
                            </motion.svg>
                        </div>
                        <motion.div className="timeline-content" variants={timelineContentVariants}>
                            <div className="timeline-content-1">
                                Intern
                            </div>
                            <div className={innerWidth>=500?"timeline-content-img-holder-long":"timeline-content-img-holder-long-shorter"}>
                                <img src={mbda} alt=""/>
                            </div>
                            <div className="timeline-content-2">
                                Software Engineer
                            </div>
                            <div className="timeline-content-2">
                                12 Weeks
                            </div>
                            <div className="timeline-content-3">
                                Stevenage
                            </div>
                        </motion.div>
                    </div>
                    <div className="timeline-date">
                        August 2023
                    </div>
                </motion.div>
                <div className="timeline-line-empty">
                    <div className="timeline-line-holder">
                        <motion.svg
                        viewBox="0 0 10 250"
                        className="timeline-line-svg-empty">
                            <motion.path
                            fill="none"
                            d="M5 240 L5 10 Z"
                            />
                        </motion.svg>
                    </div>
                </div>
                <motion.div className="timeline-section"
                variants={timelineVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{amount:0.8}}>
                    <div className="timeline-date">
                        September 2023
                    </div>
                    <div className="timeline-line">
                        <div className="timeline-line-holder">
                            <motion.svg
                            viewBox="0 0 10 800"
                            className="timeline-line-svg">
                                <motion.path
                                fill="none"
                                d="M5 790 L5 10 Z"
                                />
                            </motion.svg>
                        </div>
                        <motion.div className="timeline-content" variants={timelineContentVariants}>
                            <div className="timeline-content-img-holder">
                                <img src={uoa} alt=""/>
                            </div>
                            <motion.div className="timeline-content-2">
                                University {innerWidth>=500&&"of"} Aberdeen
                            </motion.div>
                            <motion.div className="timeline-content-5">
                                <a href="https://www.theguardian.com/education/ng-interactive/2024/sep/07/the-guardian-university-guide-2025-the-rankings">(12th in UK)</a>
                            </motion.div>
                            <motion.div className="timeline-content-3">
                                Year 2:
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Artificial Intelligence
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Distributed Systems and Security
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Enterprise Computing and Business
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ New Venture Development
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Operating Systems
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Principles of Software Engineering
                            </motion.div>
                            <motion.div className="timeline-content-4">
                            ◦ Software Engineering and Professional Practice
                            </motion.div>
                        </motion.div>
                    </div>
                    <div className="timeline-date">
                        May 2024
                    </div>
                </motion.div>
                <div className="timeline-line-empty">
                    <div className="timeline-line-holder">
                        <motion.svg
                        viewBox="0 0 10 250"
                        className="timeline-line-svg-empty">
                            <motion.path
                            fill="none"
                            d="M5 240 L5 10 Z"
                            />
                        </motion.svg>
                    </div>
                </div>
            </div>
        </motion.div>
    )

}

export default Experience;