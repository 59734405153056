import {motion, useAnimationControls} from "framer-motion"
import { useEffect } from "react"

const selectorButtonVariants = {
    clicked:{
        transition: {delay: 0.1, duration:0.5},
        x: "0",
        opacity:1,
        scale:1.2
    }, visible:{
        transition: {delay: 0.1, duration:1},
        x: "0",
        opacity:1,
        scale: 1
    }
}


const SelectorContentOption = ({titleName, selected, onClick}) => {
    const controls = useAnimationControls()
    useEffect(()=>{
        if (selected===true){
            controls.start("clicked")
        } else{
            controls.start("visible")
        }
    }, [selected, controls])
    if (titleName){
        return (
            <motion.div className="technical-page-selector-content-option"
                variants={selectorButtonVariants}
                animate={controls}
                whileHover={(!selected) && {scale: 1.05}}
                onClick={onClick}
            >{titleName}</motion.div>
        )
    }
}

export default SelectorContentOption;