import {v4 as uuidv4} from "uuid";

const TechnicalTechUsed = ({elements}) => {
    return(
        <div className="technical-page-tech-used">
            <>&nbsp;Technologies Used:</>
            <span>
                {elements&&elements.map((element)=>(
                    <div className="technical-page-tech-used-tech" key={uuidv4()}>{"◦ " + element}</div>
                ))}
            </span>
        </div>
    )
}

export default TechnicalTechUsed;