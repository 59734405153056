import {motion, useAnimationControls} from "framer-motion"
import { useEffect } from "react"
import AgileDevelopment from "../assets/Technical/TechLogos/AgileDevelopment.svg"
import AndroidStudio from "../assets/Technical/TechLogos/AndroidStudio.svg"
import C from "../assets/Technical/TechLogos/C.svg"
import CS from "../assets/Technical/TechLogos/CS.svg"
import Express from "../assets/Technical/TechLogos/Express.svg"
import HTMLandCSS from "../assets/Technical/TechLogos/HTMLandCSS.svg"
import Java from "../assets/Technical/TechLogos/Java.svg"
import JavaScript from "../assets/Technical/TechLogos/JavaScript.svg"
import MatlabOctave from "../assets/Technical/TechLogos/Matlab.svg"
import MongoDB from "../assets/Technical/TechLogos/MongoDB.svg"
import Nodejs from "../assets/Technical/TechLogos/Nodejs.svg"
import Python from "../assets/Technical/TechLogos/Python.svg"
import ReactSVG from "../assets/Technical/TechLogos/React.svg"
import SQL from "../assets/Technical/TechLogos/SQL.svg"
import WPF from "../assets/Technical/TechLogos/WPF.svg"

const svgRefsObj = {
    "Agile Development" : AgileDevelopment,
    "Android Studio" : AndroidStudio,
    "C" : C,
    "C#" : CS,
    "Express.js" : Express,
    "HTML and CSS": HTMLandCSS,
    "Java":Java,
    "JavaScript": JavaScript,
    "Matlab/Octave" : MatlabOctave,
    "MongoDB" : MongoDB,
    "Node.js" : Nodejs,
    "Python" : Python,
    "React" : ReactSVG,
    "SQL" : SQL,
    "WPF" : WPF
}

const skillVariants = {
    hidden:{
        x: "120vw",
    },hiddenMain:{
        opacity:0
    }, visible:{
        transition: {delay: 0.1, duration:1},
        x: "0",
        opacity:1,
        scale: 1
    }, clicked:{
        transition: {delay: 0.1, duration:0.5},
        x: "0",
        opacity:1,
        scale:1.2
    }, exit: {
        transition: {delay:0.1,duration:0.5},
        opacity:0
    }
}

//Replace ".", "/", "#", and " "

const Skill = ({skill, main, selected}) => {
    const controls = useAnimationControls()
    useEffect(()=>{
        if (selected===true){
            controls.start("clicked")
        } else{
            controls.start("visible")
        }
    }, [selected, controls])
    if (skill){
        return (
            <motion.img className="skill"
                variants={skillVariants}
                initial={main ? "hiddenMain" : "hidden"}
                animate={controls}
                exit="exit"
                whileHover={(!main && !selected) && {scale: 1.05}}
                alt={skill}
                src={svgRefsObj[skill]}
            >
                
            </motion.img>)

    }
}

export default Skill;