import { useEffect, useState } from "react";


export const useScrollY = () => {
    const [scrollY, setScrollY] = useState(window.scrollY);

    useEffect(() => {
        const handleResize = () => {
            setScrollY(window.scrollY);
        }
        window.addEventListener("scroll", handleResize);
        return () => window.removeEventListener("scroll", handleResize)
    }, []);
    return scrollY;
}